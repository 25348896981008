.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 88vh;
    background-color: #f5f5f5;
  }
  
  .login-form {
    background-color: #ffffff;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    max-width: 300px;
    width: 100%;
  }
  
  h2 {
    color: #333;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  p {
    color: green;
    margin-top: 10px;
  }
  